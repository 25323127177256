import { Icon } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import fr from './locales/fr.json';
import en from './locales/en.json';
import { useAppSelector } from 'state';
import { getTabOwnerEmail } from 'state/clientWorkspace/selectors';

const messagesMap = { en, fr };

const LockedWorkspace = ({ tabId }: { tabId: string }) => {
  const ownerEmail = useAppSelector((state) => getTabOwnerEmail(state, tabId));

  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <div className="my-5 pt-5 mx-auto text-secondary">
        <Icon icon="lock" size="xl" />
        <h2 className="mb-12px">
          <FormattedMessage id="workspace.locked.title" />
        </h2>
        <p data-e2e="loading-error">
          <FormattedMessage id="workspace.locked.description" values={{ ownerEmail }} />
        </p>
      </div>
    </IntlComponentBoundary>
  );
};

export default LockedWorkspace;
