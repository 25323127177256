import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { ITradeCaptureMetaData, TcErrors, TcWarnings } from 'api/tradeCapture/tradeCaptureModel';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { clearUndefined } from 'utils/clearUndefined';
import {
  fromTcError,
  fromTcErrorAsString,
  fromTcWarning,
  fromTcWarningAsString,
  previousValue,
} from 'api/tradeCapture/tradeCaptureMappingHelper';
import type { CurrencyChoice, PropertyErrors, PropertyWarnings } from 'state/share/productModel/litterals';
import type {
  TradeCaptureAmericanForwardErrors,
  TradeCaptureAmericanForwardHedgeType,
  TradeCaptureAmericanForwardLegPreviousValues,
  TradeCaptureAmericanForwardMetaData,
  TradeCaptureAmericanForwardResponseWrapper,
  TradeCaptureAmericanForwardWarnings,
} from '../../../api/tradeCapture/americanForward/tradeCaptureModel';
import type {
  FxAmericanForwardPatch,
  FxAmericanForwardValues,
  HedgeType,
  PriceType,
} from '../../../state/fxAmericanForward/model/fxAmericanForwardProductModel';
import { isDefined } from '@sgme/fp';
import type { PremiumType } from '../../../api/tradeCapture/option/tradeCaptureOptionModel';

type ActionPatch = TradeCaptureAmericanForwardResponseWrapper & WithAmericanForwardId;

export type TradeCaptureAmericanForwardResponsePatch = TradeCaptureAmericanForwardResponseWrapper &
  WithAmericanForwardId;

export type AmericanForwardTradeCapturePatch = ITradeCaptureMetaData &
  TradeCaptureAmericanForwardMetaData &
  FxAmericanForwardPatch;

interface WithAmericanForwardId {
  quoteId: string;
}

type TradeCaptureFromBackendMetaSelectorsKeys =
  | 'getAmericanForwardCurrencyPairInput'
  | 'getAmericanForwardSolvingType'
  | 'getAmericanForwardNotionalCurrency';

type TradeCaptureFromBackendMetaSelectorSelectors = Pick<Selectors, TradeCaptureFromBackendMetaSelectorsKeys>;

export const metaSelectorTradeCaptureFromBackendWith: MapStateToMetadataHOF<
  AmericanForwardTradeCapturePatch,
  TradeCaptureAmericanForwardResponsePatch,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> = (sl) => (state, patch) => {
  const quoteId = patch.quoteId;
  const getValues = makeGetValues(state, sl, quoteId);
  const tcErrors = getTradeCaptureErrors(patch.errors);
  const tcWarnings = getTradeCaptureWarnings(patch.warnings);

  return {
    ...getMetadata(patch),
    values: getValues(patch, tcErrors),
    errors: getErrors(tcErrors),
    warnings: getWarnings(tcWarnings),
  };
};

const getMetadata = (patch: ActionPatch): ITradeCaptureMetaData & TradeCaptureAmericanForwardMetaData => ({
  idVersion: patch.idVersion,
  isReadyToPrice: patch.changedFields.isReadyToPrice || false,
  isPriceObsolete: patch.changedFields.isPriceObsolete || false,
  isProductObsolete: patch.changedFields.isProductObsolete || false,
});

const makeGetValues =
  (state: AppState, sl: TradeCaptureFromBackendMetaSelectorSelectors, quoteId: string) =>
    (
      { changedFields }: TradeCaptureAmericanForwardResponsePatch,
      errors: TcErrors<TradeCaptureAmericanForwardLegPreviousValues>,
    ): Partial<FxAmericanForwardValues> => {
      const {
        currencyPair,
        hedgeAmountInCcy1String,
        hedgeAmountInCcy2String,
        hedgePaymentDate,
        hedgePriceString,
        hedgeType,
        markupCurrency,
      } = changedFields;

      const {
        amount1,
        amount2,
        negotiatedCurrency,
        callabilityStart,
        callabilityStartTenor,
        callabilityType,
        currency1,
        currency2,
        cutOffMarketPlace,
        deliveryDate,
        deliveryDateTenor,
        expiryDate,
        expiryDateTenor,
        forwardRateString,
        possibleCutOffMarketPlaces,
        premiumPaymentAmountAskString,
        premiumPaymentAmountBidString,
        premiumPaymentDate,
        premiumPaymentDateTenor,
        premiumType,
        quantity,
        side,
        solvable,
      } = changedFields.legs[0];

      const notionalCurrency =
        negotiatedCurrency !== undefined
          ? negotiatedCurrency
          : sl.getAmericanForwardNotionalCurrency(state, quoteId).value;
      const notionalAmount = notionalCurrency === 1 ? amount1 : amount2;

      let hedgeAmount: string | null | undefined
      let hedgeCurrency : CurrencyChoice | undefined

      const nullForString0 = (value: string | null | undefined) : string | null | undefined  =>
        value === '0' ? null : value

      if (isDefined(hedgeAmountInCcy1String)) {
        hedgeCurrency = 1;
        hedgeAmount = nullForString0(hedgeAmountInCcy1String)
      } else if (isDefined(hedgeAmountInCcy2String)) {
        hedgeCurrency = 2;
        hedgeAmount = nullForString0(hedgeAmountInCcy2String)
      } else if (hedgeAmountInCcy1String === null && hedgeAmountInCcy2String === null) {
        hedgeCurrency = 1;
        hedgeAmount = null
      }
     
      const hedgeRate = nullForString0(hedgePriceString);

      const solvingType = sl.getAmericanForwardSolvingType(state, quoteId);
      const premiumPaymentAmountValue = premiumPaymentAmountBidString ?? premiumPaymentAmountAskString;
      const premiumPaymentAmount =
        solvingType === 'solveRate' &&  premiumPaymentAmountValue === '0' ? null : premiumPaymentAmountValue;

      return clearUndefined<Partial<FxAmericanForwardValues>>({
        notionalAmount: computeExpectedValue<typeof notionalAmount>(
          notionalAmount,
          previousValue(errors?.amount1) ?? previousValue(errors.amount2),
        ),
        notionalCurrency: negotiatedCurrency,
        callabilityStart,
        callabilityStartTenor,
        callabilityType,
        hedgeAmount,
        hedgeCurrency,
        currency1,
        currency2,
        currencyPair,
        deliveryDate,
        deliveryDateTenor,
        expiryDate,
        expiryDateTenor,
        forwardRate: forwardRateString,
        hedgePaymentDate,
        hedgeRate,
        hedgeType: mapToHedgeType(hedgeType),
        marketPlace: cutOffMarketPlace,
        markupCurrency,
        possibleMarketPlaces: possibleCutOffMarketPlaces,
        premiumDate: premiumPaymentDate,
        premiumDateTenor: premiumPaymentDateTenor,
        premiumPaymentAmount,
        ...mapPremiumType(premiumType),
        quantity,
        side,
        solvable,
      });
    };

function computeExpectedValue<T>(value: T, defaultValue: T | null | undefined) {
  return value !== undefined ? value : defaultValue;
}

const getErrors = (
  errors: TcErrors<TradeCaptureAmericanForwardLegPreviousValues>,
): PropertyErrors<FxAmericanForwardValues> =>
  clearUndefined<PropertyErrors<FxAmericanForwardValues>>({
    notionalAmount: fromTcErrorAsString(errors.amount),
    expiryDate: fromTcError(errors.expiryDate),
  });

const getWarnings = (
  warnings: TcWarnings<TradeCaptureAmericanForwardLegPreviousValues>,
): PropertyWarnings<FxAmericanForwardValues> =>
  clearUndefined<PropertyWarnings<FxAmericanForwardValues>>({
    notionalAmount: fromTcWarningAsString(warnings.amount),
    expiryDate: fromTcWarning(warnings.expiryDate),
  });

const getTradeCaptureErrors = (responseError: TradeCaptureAmericanForwardErrors | null) =>
  responseError?.legs?.[0] ?? {};

const getTradeCaptureWarnings = (responseWarning: TradeCaptureAmericanForwardWarnings | null) =>
  responseWarning?.legs?.[0] ?? {};

function mapToHedgeType(value: TradeCaptureAmericanForwardHedgeType | undefined): HedgeType | undefined {
  if (value === 'None' || value === null) {
    return 'Live';
  }
  return value;
}

function mapPremiumType(
  premiumType: Omit<PremiumType, 'SmiledVolatility1' | 'SmiledVolatility2'> | null | undefined,
): { premiumTypeString: PriceType; premiumCurrency: CurrencyChoice } | undefined {
  switch (premiumType) {
    case 'AmountCurrency1':
      return { premiumTypeString: 'AMOUNT', premiumCurrency: 1 };
    case 'AmountCurrency2':
      return { premiumTypeString: 'AMOUNT', premiumCurrency: 2 };
    case 'Pip12':
      return { premiumTypeString: 'PPS', premiumCurrency: 1 };
    case 'Pip21':
      return { premiumTypeString: 'PPS', premiumCurrency: 2 };
    case 'PercentageCurrency1':
      return { premiumTypeString: 'PERCENT', premiumCurrency: 1 };
    case 'PercentageCurrency2':
      return { premiumTypeString: 'PERCENT', premiumCurrency: 2 };
    default:
      return undefined;
  }
}
