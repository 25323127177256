import type { Middleware } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { DispatchExt, ExtraArgument } from '../../state';
import type { Action } from '../../state/globalActions';
import type { AppState } from '../../state/model';
import { loadAllSharedTabsThunk } from './sharedTabThunks';

export const sharedTabMiddleware: Middleware<
  DispatchExt,
  AppState,
  ThunkDispatch<Readonly<AppState>, ExtraArgument, Action>
> =
  ({ dispatch }) =>
    (next) =>
      (action: Action) => {
        next(action);

        // wait required data to init shared tabs / tiles
        if (action.type === 'REFERENCE_DATA_RECEIVED') {
          dispatch(loadAllSharedTabsThunk());
          return;
        }
      };


