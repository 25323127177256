import type { SavedTile, SavedWorkspace } from 'api/workspaceService/model';
import type { OrderTemplate } from 'state/fxOrders/fxOrdersModel';
import type { GridItemPosition } from 'state/gridLayout/gridLayoutModels';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import type { ProductName } from 'state/share/productModel';
import type { TradeCaptureSessionInfos } from 'state/sharedSelectors';
import type { Collection } from 'typings/utils';
import type { SharedTab, SharedTile } from '../../epics/metaSelectors/workspace/saveWorkspaceSelectors';
import type { CollapsableKey, GrowlData, TabType } from './clientWorkspaceModel';

export type ClientWorkspaceAction =
  | ClientWorkspaceTabAdded
  | ClientWorkspaceTabRemoved
  | ClientWorkspaceTabSwitched
  | ClientWorkspaceTabRenamed
  | ClientWorkspaceClientChanged
  | ClientWorkspaceTabTypeChanged
  | ClientWorkspaceNewTileAdded
  | ClientWorkspaceTileRestored
  | ClientWorkspaceTileDuplicated
  | ClientWorkspaceTileReopened
  | ClientWorkspaceTileDeleted
  | ClientworkspaceTileClientErrorChanged
  | ClientWorkspaceTileZoomed
  | ClientWorkspaceTileMinimized
  | TileInstrumentChanged
  | TileClientOverridden
  | TileClientReset
  | GrowlOpen
  | GrowlClosed
  | ExecutionCleanup
  | ExecutionStillPending
  | ExecutionTimeoutedDeal
  | ToggleTradingDisabled
  | DisplayStreamErrorDetail
  | DisplayExecutionErrorDetail
  | DisplayPropertiesErrorDetail
  | CloseErrorDetail
  | SaveWorkspaceRequested
  | SaveWorkspaceRequestedEpic
  | SaveWorkspaceReady
  | SaveWorkspaceDone
  | SavedWorkspaceReceived
  | SaveOrderTemplatesRequestedEpic
  | SaveOrderTemplatesReady
  | SaveOrderTemplatesDone
  | SavedOrderTemplatesRequested
  | SavedOrderTemplatesReceived
  | ClientworkspaceTileToggleCollapsed
  | ToggleTradingReceived
  | TabSharingEmailsUpdated
  | UnauthorizedSharedTileUpserted
  | SharedTabUpdatedFromRemote
  | SharedTabeRemovedFromRemote
  | SharedTaSynchronizationReady
  | SharedTaSynchronizationPause
  | ClientWorkspaceIsShared;

export interface ClientWorkspaceTabAdded {
  type: 'CLIENTWORKSPACE_TAB_ADDED';
  tabId: string;
  tabType: TabType;
  tabName: string;
  clientId: string | null;
  ownerEmail: string;
  sharedWithEmails: string[];
  isWorkspaceLocked: boolean
}

export function clientWorkspaceTabAdded(
  tabId: string,
  tabType: TabType,
  tabName: string,
  clientId: string | null,
  ownerEmail: string,
  sharedWithEmails: string[] = [],
  isWorkspaceLocked = false,

): ClientWorkspaceTabAdded {
  return {
    type: 'CLIENTWORKSPACE_TAB_ADDED',
    tabId,
    tabType,
    tabName,
    clientId,
    ownerEmail,
    sharedWithEmails,
    isWorkspaceLocked
  };
}

export interface ClientWorkspaceIsShared {
  type: 'CLIENTWORKSPACE_IS_SHARED';
  tabId: string;
  isShared: boolean;
}

export function clientWorkspaceIsShared(tabId: string, isShared: boolean): ClientWorkspaceIsShared {
  return {
    type: "CLIENTWORKSPACE_IS_SHARED",
    tabId,
    isShared
  }
}

export interface ClientWorkspaceTabRemoved {
  type: 'CLIENTWORKSPACE_TAB_REMOVED';
  tabId: string;
}

export function clientWorkspaceTabRemoved(tabId: string): ClientWorkspaceTabRemoved {
  return {
    type: 'CLIENTWORKSPACE_TAB_REMOVED',
    tabId,
  };
}

export interface ClientWorkspaceTabSwitched {
  type: 'CLIENTWORKSPACE_TAB_SWITCHED';
  tabId: string | null;
}

export function clientWorkspaceTabSwitched(tabId: string | null): ClientWorkspaceTabSwitched {
  return {
    type: 'CLIENTWORKSPACE_TAB_SWITCHED',
    tabId,
  };
}

export interface ClientWorkspaceTabRenamed {
  type: 'CLIENTWORKSPACE_TAB_RENAMED';
  clientWorkspaceId: string;
  newName: string;
}

export function clientWorkspaceTabRenamed(clientWorkspaceId: string, newName: string): ClientWorkspaceTabRenamed {
  return {
    type: 'CLIENTWORKSPACE_TAB_RENAMED',
    clientWorkspaceId,
    newName,
  };
}

export interface ClientWorkspaceClientChanged {
  type: 'CLIENTWORKSPACE_CLIENT_CHANGED';
  clientWorkspaceId: string;
  clientId: string;
  previousClientId: string;
}

export function clientWorkspaceClientChanged(
  clientWorkspaceId: string,
  clientId: string,
  previousClientId: string,
): ClientWorkspaceClientChanged {
  return {
    type: 'CLIENTWORKSPACE_CLIENT_CHANGED',
    clientWorkspaceId,
    clientId,
    previousClientId,
  };
}

export interface ClientWorkspaceTabTypeChanged {
  type: 'CLIENTWORKSPACE_TAB_TYPE_CHANGED';
  clientWorkspaceId: string;
  tabType: TabType;
}

export function clientWorkspaceTabTypeChanged(
  clientWorkspaceId: string,
  tabType: TabType,
): ClientWorkspaceTabTypeChanged {
  return {
    type: 'CLIENTWORKSPACE_TAB_TYPE_CHANGED',
    clientWorkspaceId,
    tabType,
  };
}

export interface TileInstrumentChanged {
  type: 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED';
  tileId: string;
  instrument: InstrumentChoice;
  tradeCaptureSessionInfos?: TradeCaptureSessionInfos;
  currencyPair?: string | null;
  isOptionGrouped: boolean | null;
  isOptionGreekAndMktExpanded: boolean | null;
}

export function tileInstrumentChanged(
  tileId: string,
  instrument: InstrumentChoice,
  isOptionGrouped: boolean | null,
  isOptionGreekAndMktExpanded: boolean | null,
  tradeCaptureSessionInfos?: TradeCaptureSessionInfos,
  currencyPair?: string | null,
): TileInstrumentChanged {
  return {
    type: 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED',
    tileId,
    instrument,
    tradeCaptureSessionInfos,
    currencyPair,
    isOptionGrouped,
    isOptionGreekAndMktExpanded,
  };
}

export interface TileClientOverridden {
  type: 'CLIENTWORKSPACE_TILE_CLIENT_OVERRIDDEN';
  quoteId: string;
  clientId: string;
}

export function tileClientOverridden(quoteId: string, clientId: string): TileClientOverridden {
  return {
    type: 'CLIENTWORKSPACE_TILE_CLIENT_OVERRIDDEN',
    quoteId,
    clientId,
  };
}

export interface TileClientReset {
  type: 'CLIENTWORKSPACE_TILE_CLIENT_RESET';
  quoteId: string;
}

export function tileClientReset(quoteId: string): TileClientReset {
  return {
    type: 'CLIENTWORKSPACE_TILE_CLIENT_RESET',
    quoteId,
  };
}

export interface ClientWorkspaceTileDeleted {
  type: 'CLIENTWORKSPACE_TILE_DELETED';
  clientWorkspaceId: string;
  tileId: string;
  automatically: boolean;
}

export function clientWorkspaceTileDeleted(
  tileId: string,
  clientWorkspaceId: string,
  automatically = false,
): ClientWorkspaceTileDeleted {
  return {
    type: 'CLIENTWORKSPACE_TILE_DELETED',
    clientWorkspaceId,
    tileId,
    automatically,
  };
}

export interface ClientWorkspaceNewTileAdded {
  type: 'CLIENTWORKSPACE_NEW_TILE_ADDED';
  clientWorkspaceId: string;
  tileId: string;
  instrument: InstrumentChoice | 'Order';
  position?: GridItemPosition;
  isOptionGrouped: boolean | null;
  isOptionGreekAndMktExpanded: boolean | null;
}

export function clientWorkspaceNewTileAdded(
  clientWorkspaceId: string,
  tileId: string,
  instrument: InstrumentChoice | 'Order',
  isOptionGrouped: boolean | null,
  isOptionGreekAndMktExpanded: boolean | null,
  position?: GridItemPosition,
): ClientWorkspaceNewTileAdded {
  return {
    type: 'CLIENTWORKSPACE_NEW_TILE_ADDED',
    clientWorkspaceId,
    tileId,
    instrument,
    position,
    isOptionGrouped,
    isOptionGreekAndMktExpanded,
  };
}

export interface ClientWorkspaceTileRestored {
  type: 'CLIENTWORKSPACE_TILE_RESTORED';
  clientWorkspaceId: string;
  tileId: string;
  savedTile: SavedTile;
  isOptionGrouped: boolean | null;
  isOptionGreekAndMktExpanded: boolean | null;
}

export function clientWorkspaceTileRestored(
  clientWorkspaceId: string,
  tileId: string,
  savedTile: SavedTile,
  isOptionGrouped: boolean | null,
  isOptionGreekAndMktExpanded: boolean | null,
): ClientWorkspaceTileRestored {
  return {
    type: 'CLIENTWORKSPACE_TILE_RESTORED',
    clientWorkspaceId,
    tileId,
    savedTile,
    isOptionGrouped,
    isOptionGreekAndMktExpanded,
  };
}

export interface ClientWorkspaceTileDuplicated {
  type: 'CLIENTWORKSPACE_TILE_DUPLICATED';
  clientWorkspaceId: string;
  originalTileId: string;
  tileId: string;
  instrument: InstrumentChoice;
  productName: ProductName;
  position: GridItemPosition;
  isOptionGrouped: boolean | null;
  isOptionGreekAndMktExpanded: boolean | null;
}

export function clientWorkspaceTileDuplicated(
  clientWorkspaceId: string,
  originalTileId: string,
  newTileId: string,
  instrument: InstrumentChoice,
  productName: ProductName,
  position: GridItemPosition,
  isOptionGrouped: boolean | null,
  isOptionGreekAndMktExpanded: boolean | null,
): ClientWorkspaceTileDuplicated {
  return {
    type: 'CLIENTWORKSPACE_TILE_DUPLICATED',
    clientWorkspaceId,
    originalTileId,
    tileId: newTileId,
    instrument,
    productName,
    position,
    isOptionGrouped,
    isOptionGreekAndMktExpanded,
  };
}

export interface ClientWorkspaceTileReopened {
  type: 'CLIENTWORKSPACE_TILE_REOPENED';
  clientWorkspaceId: string;
  tileId: string;
  instrument: InstrumentChoice;
  productName: ProductName;
  isOptionGrouped: boolean | null;
  isOptionGreekAndMktExpanded: boolean | null;
}

export function clientWorkspaceTileReopened(
  clientWorkspaceId: string,
  newTileId: string,
  instrument: InstrumentChoice,
  productName: ProductName,
  isOptionGrouped: boolean | null,
  isOptionGreekAndMktExpanded: boolean | null,
): ClientWorkspaceTileReopened {
  return {
    type: 'CLIENTWORKSPACE_TILE_REOPENED',
    clientWorkspaceId,
    tileId: newTileId,
    instrument,
    productName,
    isOptionGrouped,
    isOptionGreekAndMktExpanded,
  };
}

export interface ClientWorkspaceTileZoomed {
  type: 'CLIENTWORKSPACE_TILE_ZOOMED';
  tabId: string;
  tileId: string;
}

export function clientWorkspaceTileZoomed(tabId: string, tileId: string): ClientWorkspaceTileZoomed {
  return {
    type: 'CLIENTWORKSPACE_TILE_ZOOMED',
    tabId,
    tileId,
  };
}

export interface ClientWorkspaceTileMinimized {
  type: 'CLIENTWORKSPACE_TILE_MINIMIZED';
  tabId: string;
}

export function clientWorkspaceTileMinimized(tabId: string): ClientWorkspaceTileMinimized {
  return {
    type: 'CLIENTWORKSPACE_TILE_MINIMIZED',
    tabId,
  };
}

export interface GrowlOpen {
  type: 'GROWL_OPEN';
  growlData: GrowlData;
}

export function growlOpen(growlData: GrowlData): GrowlOpen {
  return {
    type: 'GROWL_OPEN',
    growlData,
  };
}

export interface GrowlClosed {
  type: 'GROWL_CLOSED';
  growlId: string;
}

export function growlClosed(growlId: string): GrowlClosed {
  return {
    type: 'GROWL_CLOSED',
    growlId,
  };
}

export interface ExecutionStillPending {
  type: 'EXECUTION_STILL_PENDING';
  executionId: string;
}

export function executionStillPending(executionId: string): ExecutionStillPending {
  return {
    type: 'EXECUTION_STILL_PENDING',
    executionId,
  };
}

export interface ExecutionCleanup {
  type: 'EXECUTION_CLEANUP';
  executionId: string;
}

export function executionCleanup(executionId: string): ExecutionCleanup {
  return {
    type: 'EXECUTION_CLEANUP',
    executionId,
  };
}

export interface ExecutionTimeoutedDeal {
  type: 'EXECUTION_TIMEOUTED_DEAL';
  executionId: string;
}

export function executionTimeoutedDeal(executionId: string): ExecutionTimeoutedDeal {
  return {
    type: 'EXECUTION_TIMEOUTED_DEAL',
    executionId,
  };
}

// ////////////////////////////////////////////////////////

export interface ToggleTradingDisabled {
  type: 'TOGGLE_TRADING_DISABLED';
}

export function toggleTradingDisabled(): ToggleTradingDisabled {
  return { type: 'TOGGLE_TRADING_DISABLED' };
}

export interface ToggleTradingReceived {
  type: 'TOGGLE_TRADING_RECEIVED';
  canTrade: boolean;
}

export function toggleTradingReceived(canTrade: boolean): ToggleTradingReceived {
  return { type: 'TOGGLE_TRADING_RECEIVED', canTrade };
}

// ////////////////////////////////////////////////////////

export interface SaveWorkspaceRequested {
  type: 'SAVE_WORKSPACE_REQUESTED';
}

export function saveWorkspaceRequested(): SaveWorkspaceRequested {
  return { type: 'SAVE_WORKSPACE_REQUESTED' };
}

export interface SaveWorkspaceRequestedEpic {
  type: 'SAVE_WORKSPACE_REQUESTED_EPIC';
  workspace: SavedWorkspace;
}

export function saveWorkspaceRequestedEpic(workspace: SavedWorkspace): SaveWorkspaceRequestedEpic {
  return { type: 'SAVE_WORKSPACE_REQUESTED_EPIC', workspace };
}

export interface SaveWorkspaceReady {
  type: 'SAVE_WORKSPACE_READY';
}

export function saveWorkspaceReady(): SaveWorkspaceReady {
  return { type: 'SAVE_WORKSPACE_READY' };
}

export interface SaveWorkspaceDone {
  type: 'SAVE_WORKSPACE_DONE';
  success: boolean;
}

export function saveWorkspaceDone(success: boolean): SaveWorkspaceDone {
  return { type: 'SAVE_WORKSPACE_DONE', success };
}

export interface SavedWorkspaceReceived {
  type: 'SAVED_WORKSPACE_RECEIVED';
  savedWorkspace: SavedWorkspace;
}

export function savedWorkspaceReceived(savedWorkspace: SavedWorkspace): SavedWorkspaceReceived {
  return {
    type: 'SAVED_WORKSPACE_RECEIVED',
    savedWorkspace,
  };
}

// ////////////////////////////////////////////////////////
export interface SaveOrderTemplatesRequestedEpic {
  type: 'SAVE_ORDER_TEMPLATES_REQUESTED_EPIC';
  templates: Collection<OrderTemplate>;
}

export function saveOrderTemplatesRequestedEpic(templates: Collection<OrderTemplate>): SaveOrderTemplatesRequestedEpic {
  return { type: 'SAVE_ORDER_TEMPLATES_REQUESTED_EPIC', templates };
}

export interface SaveOrderTemplatesReady {
  type: 'SAVE_ORDER_TEMPLATES_READY';
}

export function saveOrderTemplatesReady(): SaveOrderTemplatesReady {
  return { type: 'SAVE_ORDER_TEMPLATES_READY' };
}

export interface SaveOrderTemplatesDone {
  type: 'SAVE_ORDER_TEMPLATES_DONE';
  success: boolean;
}

export function saveOrderTemplatesDone(success: boolean): SaveOrderTemplatesDone {
  return { type: 'SAVE_ORDER_TEMPLATES_DONE', success };
}

export interface SavedOrderTemplatesReceived {
  type: 'SAVED_ORDER_TEMPLATES_RECEIVED';
  templates: Collection<OrderTemplate>;
}

export interface SavedOrderTemplatesRequested {
  type: 'SAVED_ORDER_TEMPLATES_REQUESTED';
}

export function savedOrderTemplatesRequested(): SavedOrderTemplatesRequested {
  return { type: 'SAVED_ORDER_TEMPLATES_REQUESTED' };
}
export function savedOrderTemplatesReceived(templates: Collection<OrderTemplate>): SavedOrderTemplatesReceived {
  return {
    type: 'SAVED_ORDER_TEMPLATES_RECEIVED',
    templates,
  };
}

// ////////////////////////////////////////////////////////
export interface DisplayStreamErrorDetail {
  type: 'DISPLAY_STREAM_ERROR_DETAIL';
  tileId: string;
}

export function displayStreamErrorDetail(tileId: string): DisplayStreamErrorDetail {
  return {
    type: 'DISPLAY_STREAM_ERROR_DETAIL',
    tileId,
  };
}

export interface DisplayExecutionErrorDetail {
  type: 'DISPLAY_EXECUTION_ERROR_DETAIL';
  executionId: string;
}

export function displayExecutionErrorDetail(executionId: string): DisplayExecutionErrorDetail {
  return {
    type: 'DISPLAY_EXECUTION_ERROR_DETAIL',
    executionId,
  };
}

export function displayPropertiesErrorDetail(quoteId: string) {
  return {
    type: 'DISPLAY_PROPERTIES_ERROR_DETAIL',
    quoteId,
  } as const;
}

export type DisplayPropertiesErrorDetail = ReturnType<typeof displayPropertiesErrorDetail>;

export interface CloseErrorDetail {
  type: 'CLOSE_ERROR_DETAIL';
}

export function closeErrorDetail(): CloseErrorDetail {
  return {
    type: 'CLOSE_ERROR_DETAIL',
  };
}

export interface ClientworkspaceTileClientErrorChanged {
  type: 'CLIENTWORKSPACE_TILE_CLIENT_ERROR_CHANGED';
  tileId: string;
  clientError: boolean;
}

export function changeTileClientError(tileId: string, clientError: boolean): ClientworkspaceTileClientErrorChanged {
  return {
    type: 'CLIENTWORKSPACE_TILE_CLIENT_ERROR_CHANGED',
    tileId,
    clientError,
  };
}
export interface ClientworkspaceTileToggleCollapsed {
  type: 'CLIENTWORKSPACE_TILE_TOGGLE_COLLAPSED';
  tileId: string;
  keyName: CollapsableKey;
}

export function toggleCollapsed(tileId: string, keyName: CollapsableKey): ClientworkspaceTileToggleCollapsed {
  return {
    type: 'CLIENTWORKSPACE_TILE_TOGGLE_COLLAPSED',
    tileId,
    keyName,
  };
}

export interface RestoredTiles<T> {
  [tileId: string]: T;
}

// ███████╗██╗  ██╗ █████╗ ██████╗ ██╗███╗   ██╗ ██████╗
// ██╔════╝██║  ██║██╔══██╗██╔══██╗██║████╗  ██║██╔════╝
// ███████╗███████║███████║██████╔╝██║██╔██╗ ██║██║  ███╗
// ╚════██║██╔══██║██╔══██║██╔══██╗██║██║╚██╗██║██║   ██║
// ███████║██║  ██║██║  ██║██║  ██║██║██║ ╚████║╚██████╔╝
// ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝

export type TabSharingEmailsUpdated = {
  type: 'TAB_SHARING_EMAILS_UPDATED';
  tabId: string;
  allEmails: string[];
};

export const tabSharingEmailsUpdated = (tabId: string, allEmails: string[]): TabSharingEmailsUpdated => {
  return {
    type: 'TAB_SHARING_EMAILS_UPDATED',
    tabId,
    allEmails,
  };
};

// ---------------------------------------------------

export type SharedTabUpdatedFromRemote = {
  type: 'SHARED_TAB_UPDATED_FROM_REMOTE';
  tabId: string;
  tab: SharedTab;
};

export const sharedTabUpdatedFromRemote = (tabId: string, tab: SharedTab): SharedTabUpdatedFromRemote => {
  return {
    type: 'SHARED_TAB_UPDATED_FROM_REMOTE',
    tabId,
    tab,
  };
};

// ---------------------------------------------------

export type UnauthorizedSharedTileUpserted = {
  type: 'UNAUTHORIZED_SHARED_TILE_UPSERTED';
  tileId: string;
  tile: SharedTile;
};

export const unauthorizedSharedTileUpserted = (tileId: string, tile: SharedTile): UnauthorizedSharedTileUpserted => {
  return {
    type: 'UNAUTHORIZED_SHARED_TILE_UPSERTED',
    tileId,
    tile,
  };
};

// ---------------------------------------------------

export type SharedTabeRemovedFromRemote = {
  type: 'SHARED_TAB_REMOVED_FROM_REMOTE';
  tabId: string;
};

export const sharedClientWorkspaceRemoved = (tabId: string): SharedTabeRemovedFromRemote => {
  return {
    type: 'SHARED_TAB_REMOVED_FROM_REMOTE',
    tabId,
  };
};

// ---------------------------------------------------

export type SharedTaSynchronizationReady = {
  type: 'SHARED_TAB_SYNCHRONIZATION_READY';
  tabId: string;
};

export const sharedTaSynchronizationReady = (tabId: string): SharedTaSynchronizationReady => {
  return {
    type: 'SHARED_TAB_SYNCHRONIZATION_READY',
    tabId,
  };
};

// ---------------------------------------------------

export type SharedTaSynchronizationPause = {
  type: 'SHARED_TAB_SYNCHRONIZATION_PAUSE';
  tabId: string;
};

export const sharedToSynchronizationPause = (tabId: string): SharedTaSynchronizationPause => {
  return {
    type: 'SHARED_TAB_SYNCHRONIZATION_PAUSE',
    tabId,
  };
};
