import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';
import type { FxOptionState, OptionLegsOrientation } from '../model/optionProduct';
import { getOptionLeafLegState } from './optionLeg';
import type { OptionStoreModelChanges } from 'epics/metaSelectors/option/tradeCaptureModel';
import { isDefined } from '@sgme/fp';

export function getOptionState(state: AppState, optionId: string): FxOptionState {
  return throwIfUndefined(
    state.fxOptions.options[optionId],
    `Option state for ${optionId} does not exist`,
  );
}

export function getOptionActiveRfsId(state: AppState, optionId: string): string | null {
  const { currentStreamId, lastStreamId } = getOptionState(state, optionId);
  return lastStreamId ?? currentStreamId;
}

export function getOptionLegOrientation(state: AppState, optionId: string): OptionLegsOrientation {
  return getOptionState(state, optionId).orientation;
}

export function getOptionGroupExpanded(state: AppState, quoteId: string, expiry: string): boolean {
  const option = getOptionState(state, quoteId);
  return isOptionGroupExpanded(option, expiry);
}

export function isOptionGroupExpanded(option: FxOptionState, expiry: string): boolean {
  return option.expanded.includes(expiry);
}

export function canOptionGroupsExpand(state: AppState, quoteId: string): boolean {
  const option = getOptionState(state, quoteId);
  const legs = option.legIds.map(legId => getOptionLeafLegState(state, legId));
  const expiryGroups = legs.map(leg => leg.values.expiryDate);

  return (
    expiryGroups.filter((value, index) => !!value && expiryGroups.indexOf(value) !== index).length >
    option.expanded.length
  );
}

export function canOptionGroupsCollapse(state: AppState, quoteId: string): boolean {
  const option = getOptionState(state, quoteId);
  return option.expanded.some(g => !!g);
}

export function haveOptionCollapsibleGroups(state: AppState, quoteId: string): boolean {
  const option = getOptionState(state, quoteId);
  const legs = option.legIds.map(legId => getOptionLeafLegState(state, legId));
  const expiryGroups = legs.map(leg => leg.values.expiryDate);

  return (
    expiryGroups.filter((value, index) => !!value && expiryGroups.indexOf(value) !== index).length >
    0
  );
}

export function isCurrencyPairPresent(state: AppState, [optionId, changes]: OptionChanges) {
  const oldCurrencyPair = getOptionState(state, optionId).values.currencyPair

  return isDefined(changes.currencyPair) || isDefined(oldCurrencyPair)
}
type OptionChanges = [optionId: string, changes: OptionStoreModelChanges];
