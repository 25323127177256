import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { strictEntries } from 'utils/object/entries';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import { type IWorkspaceTabs, tabNameGenerators } from '../clientWorkspaceModel';

export const clientWorkspaceTabsReducer: Reducer<IWorkspaceTabs> = (
  tabs: IWorkspaceTabs = {},
  action: Action,
): IWorkspaceTabs => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TAB_ADDED':
      return addKey(tabs, action.tabId, {
        name: action.tabName,
        isNamePristine: true,
        type: action.tabType,
        clientId: action.clientId,
        tiles: [],
        zoomedTile: null,

        // sharing
        ownerEmail: action.ownerEmail,
        sharedWithEmails: action.sharedWithEmails,
        isShared: false,
        isSynchronizable: false,
        isWorkspaceLocked: action.isWorkspaceLocked
      });

    case 'ORDER_SUBMISSION_SUCCESS': {
      if (action.orderId === null) {
        return tabs;
      }

      const [tabId] = strictEntries(tabs).find(([, { tiles }]) => tiles.includes(action.quoteId)) ?? [];

      if (tabId === undefined) {
        return tabs;
      }

      return updateKey(tabs, tabId, ({ tiles }) => ({
        tiles: action.order ? [...tiles.filter((t) => t !== action.quoteId), action.orderId!] : tiles,
      }));
    }

    case 'BLOTTER_ORDER_OPEN_TILE':
      return updateKey(tabs, action.currentTabId, ({ tiles }) => ({
        tiles: tiles.includes(action.orderId) ? tiles : [...tiles, action.orderId],
      }));

    case 'CLIENTWORKSPACE_TAB_REMOVED':
      return removeKey(tabs, action.tabId);

    case 'CLIENTWORKSPACE_CLIENT_CHANGED':
      return updateKey(tabs, action.clientWorkspaceId, () => ({
        clientId: action.clientId,
      }));

    case 'CLIENTWORKSPACE_TAB_TYPE_CHANGED':
      return updateKey(tabs, action.clientWorkspaceId, (state) => {
        const findLastNumber = /^.*\s(\d+)$/g.exec(state.name);
        const shouldNotUpdateName = !state.isNamePristine || findLastNumber === null || findLastNumber.length < 2;

        return {
          name: shouldNotUpdateName
            ? state.name
            : tabNameGenerators[action.tabType](Number.parseInt(findLastNumber![1], 10)),
          type: action.tabType,
        };
      });

    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_RESTORED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return updateKey(tabs, action.clientWorkspaceId, ({ tiles }) => ({
        tiles: tiles.concat([action.tileId]),
      }));

    case 'CLIENTWORKSPACE_TILE_DELETED':
      return updateKey(tabs, action.clientWorkspaceId, ({ tiles }) => ({
        tiles: tiles.filter((tileId) => tileId !== action.tileId),
      }));

    case 'CLIENTWORKSPACE_TAB_RENAMED':
      return updateKey(tabs, action.clientWorkspaceId, () => ({
        name: action.newName,
        isNamePristine: false,
      }));

    case 'CLIENTWORKSPACE_TILE_ZOOMED':
      return updateKey(tabs, action.tabId, () => ({ zoomedTile: action.tileId }));

    case 'CLIENTWORKSPACE_TILE_MINIMIZED':
      return updateKey(tabs, action.tabId, () => ({ zoomedTile: null }));

    case 'TAB_SHARING_EMAILS_UPDATED':
      return updateKey(tabs, action.tabId, () => ({ sharedWithEmails: action.allEmails }));

    case 'CLIENTWORKSPACE_IS_SHARED':
      return updateKey(tabs, action.tabId, () => ({ isShared: action.isShared }));

    case 'SHARED_TAB_SYNCHRONIZATION_READY':
      return updateKey(tabs, action.tabId, () => ({ isSynchronizable: true }));

    case 'SHARED_TAB_SYNCHRONIZATION_PAUSE':
      return updateKey(tabs, action.tabId, () => ({ isSynchronizable: false }));

    default:
      return tabs;
  }
};
