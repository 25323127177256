import type { Instrument, TileInstrument } from 'state/referenceData/referenceDataModel';
import type { ImmutableCollection } from 'typings/utils';
import type { SavedTile } from '../../api/workspaceService/updaters/updater9/model';

interface IClientWorkspaceState {
  tradingDisabled: boolean;
  saveState: SaveState;
  tabs: IWorkspaceTabs;
  activeTab: string | null;
  tiles: IWorkspaceTiles;
  unauthorizedTiles: Record<string, SavedTile & { tileId: string }>;
  growls: IWorkspaceGrowls;
}

// saveState

export type SaveState = 'READY' | 'PENDING' | 'FAIL' | 'SUCCESS';

// tabs

export type IWorkspaceTabs = ImmutableCollection<IWorkspaceTabState>;

export interface IWorkspaceTabState {
  readonly name: string;
  readonly isNamePristine: boolean;
  readonly type: TabType;
  readonly clientId: string | null;
  readonly tiles: readonly string[];
  readonly zoomedTile: string | null;

  // sharing
  readonly ownerEmail: string;
  readonly sharedWithEmails: string[];
  readonly isShared: boolean;
  readonly isSynchronizable: boolean; // when false, the shared tab changes are not sent to the webapi (during initialization for example)
  readonly isWorkspaceLocked: boolean
}

export const tabNameGenerators: Record<TabType, (index: number) => string> = {
  tiles: (index: number) => `Workspace ${index}`,
  bulkTrade: (index: number) => `Bulk trade ${index}`,
  bulkOrder: (index: number) => `Bulk order ${index}`,
};

export type TabType = 'tiles' | 'bulkTrade' | 'bulkOrder';

export type ClientWorkspaceState = Readonly<IClientWorkspaceState>;

// tiles

export type IWorkspaceTiles = ImmutableCollection<IWorkspaceTileState>;

export interface IWorkspaceTileState {
  readonly clientError?: boolean;
  readonly expanded: readonly CollapsableKey[];
  readonly instrument: Instrument;
  readonly clientWorkspaceId: string;
  readonly overrideClientId: string | null;
}

export type CollapsableKey = (typeof COLLAPSABLE_KEYS)[keyof typeof COLLAPSABLE_KEYS];

export const MARKET_DATA = 'MarketData' as const;
export const GREEKS = 'Greeks' as const;
export const COMMERCIAL_MARGIN = 'CommercialMargin' as const;
export const OTHER_ATTRIBUTES = 'OtherAttributes' as const;
export const EDITABLE_ATTRIBUTES = 'EditableAttributes' as const;

export const COLLAPSABLE_KEYS = {
  marketData: MARKET_DATA,
  greeks: GREEKS,
  commercialMargin: COMMERCIAL_MARGIN,
  otherAttributes: OTHER_ATTRIBUTES,
  editableAttributes: EDITABLE_ATTRIBUTES,
};

// growls

export type IWorkspaceGrowls = readonly GrowlData[];

export interface GrowlData {
  id: string;
  instrument: TileInstrument;
}
